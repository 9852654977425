<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Selección</el-dropdown-item>
              <el-dropdown-item command="filtrar">Filtrar por IDs</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.mass">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="elimina">Eliminar</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Rutas:</span>
          <el-select v-if="preventas.length != 0" v-model="pre" placeholder="Rutas" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus:
          <el-select v-model="estatus" placeholder="ESTATUS" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in status"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @click:row="eventRow"
          >
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
          <!--<div class="row pt-2 justify-content-between">
            <div class="col-4">
              Registros de {{ start + 1 }} A {{ start + itemsT }} de {{ total }}
            </div>
            <div class="col-6">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                :total-visible="7"
                @input="newPagina($event)"
              ></v-pagination>
            </div>
          </div>-->
      </div>
    </div>

    <!-- modals-->
    <el-dialog
      title="Filtrar Registros"
      :visible.sync="dgFiltrar"
      width="30%">
      <p>Ingrese los Ids</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Ids separados por coma (,)"
        v-model="idsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrar = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistros">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Filtrar Registros NUDs"
      :visible.sync="dgFiltrarNuds"
      width="30%">
      <p>Ingrese los NUDs</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="NUDs separados por coma (,)"
        v-model="nudsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrarNuds = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistros">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Activar/Desactivar Frecuencias"
      :visible.sync="dgActivar"
      width="35%">
      <div data-app>
                <form class="form-horizontal" ref="frActiva">
                  <div class="row">
                    <label class="col-md-3 col-form-label">Región</label>
                    <div class="col-md-9">
                    <el-select v-model="itmRegion" placeholder="REGIONES" size="mini" @change="getGerencias(1)">
                        <el-option
                          v-for="item in itemsRegiones"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Gerencia</label>
                    <div class="col-md-9">
                      <el-select v-model="itmGerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(1)">
                        <el-option
                          v-for="item in itemsGerencias"
                          :key="item.i"
                          :label="item.d"
                          :value="item.i">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Cedis</label>
                    <div class="col-md-9">
                    <el-select v-model="itmCedis" placeholder="CEDIS" size="mini" @change="getPreventa(1)">
                      <el-option
                        v-for="item in itemsCedis"
                        :key="item.i"
                        :label="item.d"
                        :value="item.i">
                      </el-option>
                    </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-3 col-form-label">Ruta</label>
                    <div class="col-md-9">
                    <el-select v-model="itmRuta" placeholder="RUTA" size="mini" multiple>
                      <el-option
                        v-for="item in itemsRutas"
                        :key="item.i"
                        :label="item.d"
                        :value="item.i">
                      </el-option>
                    </el-select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 offset-4 mt-3">
                      <el-button type="primary" size="mini" @click="activaFrecuencias">Activar/Desactivar</el-button>
                    </div>
                  </div>
                </form>
      </div>
    </el-dialog>
    <el-dialog
       title="Cambios Masivos"
       :visible.sync="dgCambios"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :auto-upload="false"
         :on-change="masivoArchivo"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgCambios = false">Cancelar</el-button>
         <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
       </span>
     </el-dialog>

   <el-dialog
      title="Re-Asignar Registros"
      :visible.sync="dgAsignar"
      width="30%">
        <div data-app>
          <p>Lista de Ids</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Ids separados por coma (,)"
            v-model="idsReasignar"
            class="pb-4">
          </el-input>
          <div class="text-right">
            <el-button @click="dgAsignar = false">Cancelar</el-button>
            <el-button type="primary" @click="eliminarRegistros">Eliminar</el-button>
          </div>
        </div>
        </el-dialog>
        <el-dialog
          :title="currentClient"
          :visible.sync="dgDetalle"
          top="10px"
          fullscreen>
            <div class="row">
                <!--<div class="col-md-4 col-form-label">
                <p>
                <center><b>Aviso clientes</b></center>
                </p>
                <p>
                1. Responsable del tratamiento de sus datos personales
                </p>
                <p>
                  Grupo Gepp, S.A.P.I. de C.V., (“GEPP”), , persona moral, debidamente constituida conforme a las leyes de la República Mexicana, 
                  con domicilio en Av. Santa Fe No. 485 piso 4, Col. Cruz Manca, México, Ciudad de México, Cuajimalpa de Morelos, CP 05349,
                  es la persona responsable del tratamiento de sus datos personales.
                </p>
                  <center><img :src="currentImagenFirma" alt="" height="200px" /></center>
                  <div class="text-center">
                    <el-button type="primary" @click="descargaContrato">Descargar Contrato</el-button>
                  </div>
                </div>-->
                <div class="col-md-2 col-form-label text-center">
                  <img :src="currentImagenCliente" alt="" height="350px" />
                </div>
                <div class="col-md-2 col-form-label text-center">
                  <img :src="currentImagen" alt="" height="350px" />
                </div>
                <div class="col-md-2 col-form-label text-center">
                  <img :src="currentImagenTicket" alt="" height="350px" />
                </div>
                <div class="col-md-2 col-form-label text-center">
                  <img :src="currentImagenFirma" alt="" height="350px" />
                </div>
                  <div class="col-md-4">
                      <div id="customSkinMap" class="map" ></div>
                  </div>
            </div>
        </el-dialog>
    <!-- end modals-->
    <v-overlay :value="overlay" z-index="2002">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      usuario: null,
      usuarios: [],
      pre: null,
      preventas: [
        ],
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      validado: null,
      validados: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'No Validado' },
      { i:  1, d: 'Validado' }
      ],
      estatus: null,
      status: [
        { i: -1, d: 'Todos' },
        { i:  3, d: 'Entregado' },
        { i:  2, d: 'Cerrado' },
        { i:  4, d: 'No Localizado' },
        { i:  5, d: 'inconsistencia' },
        { i:  6, d: 'Kit Rechazado' }
      ],
      frecuencia: null,
      frecuencias: [
        { i: '-1',   d: 'Todos' },
        { i:  'L,J', d: 'L,J' },
        { i:  'M,V', d: 'M,V' },
        { i:  'X,S',d: 'X,S' }
      ],
      dateTimePicker: [],
      desserts: [],
      headers: [
        { text: 'ID', value: 'valCar_id', width: 50,  sortable: false },
        { text: 'Cartera ID', value: 'car_id', width: 100,  sortable: false },
        { text: 'Metros', value: 'valCar_metros_gps', width: 150,  sortable: false },
        { text: 'Promotor', value: 'valCar_uid', width: 150,  sortable: false },
        { text: 'Region', value: 'valCar_reg', width: 150,  sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 150,  sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 150,  sortable: false },
        { text: 'Estatus validacion', value: 'valCar_val_status', width: 50,  sortable: false },
        { text: 'Ruta', value: 'valCar_rt_prev', width: 70,  sortable: false },
        { text: 'NUD', value: 'valCar_NUD'   , width: 50,  sortable: false },
        { text: 'Nombre', value: 'valCar_nombre', width: 200,  sortable: false },
        { text: 'Direccion', value: 'valCar_dir', width: 250,  sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext', width: 70,  sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int', width: 70,  sortable: false },
        { text: 'Colonia', value: 'valCar_col', width: 200,  sortable: false },
        { text: 'Calle 1', value:  'valCar_cll_1', width: 200,  sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 200,  sortable: false },
        { text: 'C.P.', value: 'valCar_cp', width: 50,  sortable: false },
        { text: 'Telefono 1', value: 'valCar_tel_mot', width: 100,  sortable: false },
        { text: 'Comentario', value: 'valCar_comments', width: 250,  sortable: false, cellClass: 'truncate' },
        { text: 'Foto fachada', value: 'valCar_foto_fachada', width: 150,  sortable: false, cellClass: 'truncate' },
        { text: 'Foto cliente', value: 'valCar_foto_cliente', width: 150,  sortable: false, cellClass: 'truncate' },
        { text: 'Firma', value: 'valCar_firma_digital', width: 150,  sortable: false, cellClass: 'truncate' },
        { text: 'Nombre 2', value: 'valCar_nombre_adi', width: 200,  sortable: false },
        { text: 'GPS', value: 'valCar_gps', width: 200,  sortable: false },
        { text: 'Fecha Envio', value: 'valCar_upload_date', width: 120,  sortable: false },
        { text: 'Hora Envio', value: 'valCar_upload_time', width: 100,  sortable: false },
        { text: 'Update Massive', value: 'valCar_ms_updt', width: 50,  sortable: false },
        { text: 'Archivo', value: 'valCar_s3_bucket', width: 50,  sortable: false }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      idsFiltrar: null,
      dgActivar: false,
      clNuevo: {
        name: '',
        phone: '',
        phone2: '',
        folio: '',
        oper: 1,
        region: '',
        gerencia: '',
        cedis: '',
        ruta: ''
      },
      nuevoValid: true,
      nameRules: [
        v => !!v || 'Nombre Requerido'
      ],
      folioRules: [
        v => !!v || 'Folio Requerido'
      ],
      phoneRules: [
        v => (v && v.length >= 10) || 'Escriba un Número valido',
      ],
      dgCambios: false,
      dgAsignar: false,
      //nwRegion: '',
      itemsRegiones: [],
      itmRegion: null,
      itemsGerencias: [],
      itmGerencia: null,
      itemsCedis: [],
      itmCedis: null,
      itemsRutas: [],
      itmRuta: null,
      resultadoMasivo: '',
      callCenter: '',
      itemsCallCenter: [],
      masvisoFileXls: null,
      dgDetalle: false,
      currentClient: '',
      currentImagen: '',
      currentImagenFirma: '',
      currentImagenTicket: '',
      currentImagenCliente: '',
      permisos: {},
      nudsFiltrar: null,
      dgFiltrarNuds: false,
      idsReasignar: null
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'filtrar':
          this.dgFiltrar = true
        break
        case 'cambiar':
          this.dgCambios = true
        break
        case 'elimina':
          this.dgAsignar = true
        break
      }
    },
    rowClass(item) {
      //console.log(item);
      if(item.valCar_metros_gps > 0 && item.valCar_metros_gps < 25) { return 'table-trans' }
      if(item.valCar_metros_gps > 25 && item.valCar_metros_gps < 75) { return 'table-alt' }
      if(item.valCar_metros_gps > 75 ) { return 'table-baj' }
      {
        return 'table-xxx'
      }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    filtrar() {
      this.idsFiltrar = null
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      this.overlay = true
      let json ={
        c: "carteraFin",
        cmd: "getCartFinData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        //ip: "189.167.28.167",
        opr: 0,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        rta_id: this.pre ? this.pre.join(',') : '',
        sts_id: this.estatus ? this.estatus.join(',') : '',
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        //date_fr: this.dateTimePicker[0],
        //date_to: this.dateTimePicker[1]
      };

      if(this.idsFiltrar){
        json.ids = this.idsFiltrar
        this.dgFiltrar = false
      }else {
        json.date_fr = this.dateTimePicker[0]
        json.date_to = this.dateTimePicker[1]
      }


      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
          var resp = data.data.rows
          this.desserts = resp;
          this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
          this.total = data.data.results
          this.itemsT = resp.length
          this.overlay = false
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
      })
    },
    getRegiones(sc){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
       if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
          if(sc == 0){
            this.regiones = data.data
            //
            this.regiones.unshift({ i: -1, d: 'TODAS' })
            //this.itemsRegiones.splice(0,1)
          }else if(sc == 1){
            this.itemsRegiones = data.data
          }
        }
        
      })
    },
    getGerencias(sc){
        this.gerencia = null
        this.cedi = null
        this.pre = null
      if(this.region == -1){

        this.gerencias = []
        this.cedis = []
        this.preventas = []
        //this.usuarios = []

        return false
      }

      let region = ''
      if(sc == 0){
        region = this.region
      }else if(sc == 1){
        region = this.itmRegion
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: region
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else if(sc == 1){
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){

        this.cedi = null
        this.pre = null
      if(this.gerencia == -1){
        this.cedis = []
        this.preventas = []
        //this.usuarios = []

        return false
      }
      let gerencia = ''
      if(sc == 0){
        gerencia = this.gerencia
      }else if(sc == 1){
        gerencia = this.itmGerencia
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: gerencia
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else if(sc == 1){
          this.itemsCedis = data.data
        }
      })
    },
    getPreventa(sc){

        this.pre = null
      if(this.cedi == -1){
        this.preventas = []
        return false
      }
      let cedi = ''
      if(sc == 0){
        //this.getUsuarios()
        cedi = this.cedi
      }else if(sc == 1){
        cedi = this.itmCedis
      }

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: 'all', d: 'TODAS' })
        }else if(sc == 1) {
          this.itemsRutas = data.data
        }
      })
    },
    getUsuarios(){
      let json ={
        c: "geppValida",
        cmd: "getUserJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.usuarios = data.data
        this.usuarios.unshift({ i: -1, d: 'TODOS' })
      })
    },
    descargaFiltro(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : ''
      str += this.estatus ? "&sts_id="+this.estatus : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = this.$api_url+"?c=carteraFin&cmd=getCartFinDataexportlist&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr=0"+str;
    },
    filtrarRegistros(){
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    verificaTel(){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "CarteraFin",
          cmd: "verifiTel",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          Tel: this.clNuevo.phone
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          console.log(data.data);
          if(data.data.success) {
            resolve(true)
          }else {
            resolve(false)
          }
        })
      })
      return respuesta
    },
    async crearNuevo(){
      if(this.$refs.nwActivar.validate()){
        var val = await this.verificaTel()
        if(val){
          let json = {
          usuarioid: this.uid,
          data: [
              {
                  a1: this.clNuevo.name,
                  a7: this.clNuevo.phone,//tel,
                  a8: this.clNuevo.phone2,//tel2,
                  h0: "3",
                  h4: this.clNuevo.folio,
                  region: this.clNuevo.region,
                  gerencia: this.clNuevo.gerencia,
                  cedi: this.clNuevo.cedis,
                  ruta: this.clNuevo.ruta
              }
              ]
          }
              // POST
            axios.post("https://c2.tmsglobal.com.mx/site/CAPENH/dispatcher.php?c=CarteraFin&cmd=validacionInsert&exec=self"
                        +"&hash="+this.hash+"&uid="+this.uid, JSON.stringify(json))
            .then(data=>{
              console.log(data.data);
              if(data.data.success) {
                  this.$notify({
                    title: '',
                    message: 'Clientes Registrado con éxito.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }else {
                  this.$notify({
                    title: 'Error',
                    message: 'El cliente no se registro de forma correcta.',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'warning',
                    timeout: 4000,
                    icon: 'tim-icons icon-bell-55'
                  });
              }
            })
        }else{
          this.$notify({
            title: 'Error',
            message: 'Se ha superado el número de registros.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }
    },
    cambiosMasivos(){
    /*  let json ={
        c: "CarteraFin_all",
        cmd: "processForm",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar,
        wnd_upld_xls: this.masvisoFileXls,
        wnd_upld_act: 2,
        wnd_upld_uid: this.uid
      };*/

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masvisoFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post(this.$api_url + "?c=CarteraFin&cmd=massiveUpdate&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    masivoArchivo(file){
      this.masvisoFileXls = file.raw
    },
    getCallCenters(){
      let json ={
        c: "CallCenterRefresco",
        cmd: "getCallCenterUsersJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data.data);
        this.itemsCallCenter = data.data
      })
    },
    eventRow(record){
      //console.log(record);
      this.currentClient = record.valCar_nombre
      this.dgDetalle = true
      this.currentImagen = "https://tms-cdg2.s3.us-west-2.amazonaws.com/"+record.valCar_foto_fachada
      this.currentImagenFirma = "https://tms-cdg2.s3.us-west-2.amazonaws.com/"+record.valCar_firma_digital
      this.currentImagenCliente = "https://tms-cdg2.s3.us-west-2.amazonaws.com/"+record.valCar_foto_cliente
      this.currentImagenTicket = "https://tms-cdg2.s3.us-west-2.amazonaws.com/"+record.valCar_foto_ticket
      var latlng = record.valCar_gps.split(',')
      var lat = parseFloat(latlng[0])
      var lng = parseFloat(latlng[1])
      this.loadMapa(lat, lng)
    },
    loadMapa(lat, lng){
    loader.load().then(function(google) {

      // Custom Skin & Settings Map Begin
      const myLatlng2 = new google.maps.LatLng(lat, lng); //(23.6, -102.5);
      const mapOptions2 = {
        zoom: 10,
        center: myLatlng2,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true
      };

      // console.log(document.getElementById("customSkinMap"));
      const map2 = new google.maps.Map(
        document.getElementById("customSkinMap"),
        mapOptions2
      );

      const marker2 = new google.maps.Marker({
        position: myLatlng2,
        title: "Custom Skin & Settings Map!"
      });

      marker2.setMap(map2);
      // Custom Skin & Settings Map End
    });
    },
    descargaFotos(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : '0'
      str += this.gerencia ? "&ger_id="+this.gerencia : '0'
      str += this.cedi ? "&cds_id="+this.cedi : '0'
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : '0'
      str += this.estatus ? "&sts_id="+this.estatus : '0'
      str += this.validado ? "&valid="+this.validado : '0'
      str += this.frecuencia ? "&frecid="+this.frecuencia : '0'
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''
      
      window.location = this.$api_url+"?c=carteraFin&cmd=DownloadFotos&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr_id=0"+str+"&pId=0";
      
    },
    activaFrecuencias(){
      this.overlay = true
      let json ={
        c: "CarteraFin",
        cmd: "activarFrec",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsEliminar,
        frm_fin_reg: this.itmRegion,
        frm_fin_ger: this.itmGerencia,
        frm_fin_cds: this.itmCedis,
        rts: this.itmRuta ? this.itmRuta.join(',') : '',
        con: 1
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
        this.dgActivar = false
        this.pagination.page = 1
        this.start = 0
        this.getData()

          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    eliminarRegistros(){
      this.overlay = true
      let json ={
        c: "CarteraFin",
        cmd: "getUpEliminarV",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id: this.idsReasignar
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
        this.dgAsignar = false
        this.pagination.page = 1
        this.start = 0
        this.getData()

          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    }
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_fin.actions

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

      this.getRegiones(0)
      this.getData()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}

/*
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}
*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}
/*
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}*/

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 350px;
    width: 100%;
  }

.table-trans {
  background-color: #85C1E9;
}
.table-alt {
  background-color: #45B39D;
}
.table-baj {
  background-color: #F78181;
}
.table-pen {
  background-color: #C8A2C8;
}
.table-inco {
  background-color: #efef4c;
}
.table-pro {
  background-color: #C8A2C8;
}
.table-xxx {
  background-color: #fff;
}

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>